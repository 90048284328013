import React from 'react'
import Profile from '../components/Profile'
import PrivateRoute from '../components/PrivateRoute'
import Layout from '../components/Layout'
import { isBrowser } from '../utils/misc'
const App = ({ data }) => {
  if (isBrowser) {
    return (
      <Layout>
        <PrivateRoute path="/" component={Profile} fsOnly="false" data={data} />
      </Layout>
    )
  } else {
    return null
  }
}

export default App
